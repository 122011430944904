import {Tooltip, Button, Link} from "@nextui-org/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    

    return (
        <div className="w-full flex flex-col gap-2 items-center justify-center">
            <div>
                <h5 className="w-full text-center font-jbmono">SOCIAL MEDIA</h5>
                <div className="flex gap-4 items-center justify-center mb-4">
                    <Tooltip content="LinkedIn">
                        <Button href="https://www.linkedin.com/in/jakub-jaworski-16b089275/" as={Link} isIconOnly aria-label="LinkedIn">
                            <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                        </Button>
                    </Tooltip>
                    <Tooltip content="GitHub">
                        <Button href="https://github.com/Kait0u" as={Link} isIconOnly aria-label="GitHub">
                            <FontAwesomeIcon icon={faGithub} size="lg" />
                        </Button>
                    </Tooltip>
                </div>
                <p className="w-full text-center text-sm font-nunito" >Ⓒ 2023 Kait0u / Jakub Jaworski</p>
            </div>
        </div>
    );
}

export default Footer;