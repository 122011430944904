import { Divider } from "@nextui-org/react";
import Title from "./layout_components/Title";
import Nav from "./layout_components/Nav";
import Footer from "./layout_components/Footer";

function LayoutNav({children}) {
    return (
        <div className="h-full w-full flex flex-col justify-between gap-6">
            <div className="w-full flex flex-col items-center justify-center">
                <Title margin="0"/>
                <Nav />
                <Divider className="w-full"/>
                {children}
            </div>
            <Footer />
      </div>
    );
}

export default LayoutNav;