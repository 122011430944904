import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"
import LayoutNav from "./layouts/LayoutNav"
import {Card, CardBody, CardFooter, Button, CircularProgress} from "@nextui-org/react";


function NoPage() {
    const [value, setValue] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (value === 0) {
                clearInterval(interval);
                return navigate("/"); // Change to redirect later
            }
            else setValue((v) => (v - 1));
        }, 1000);

        return () => clearInterval(interval);
      }, [value, navigate]);

    return (
      <LayoutNav>
        <Helmet>
            <title>Kait0u's Portfolio | Page not found!</title>
        </Helmet>
        <div className="mb-2" />
        <div className="py-8 flex flex-col justify-center items-center mb-8">
            <h2 className="font-jbmono">
                <span className="text-red-500">404!</span>
            </h2>
            <p>Something must have gone wrong...</p>
            <p>Hang on, I'll redirect you to back to the home page :)</p>
        </div>
        <Card>
            <CardBody className="w-full flex flex-col justify-center items-center">
                <CircularProgress 
                aria-label="Readying..."
                size="lg"
                showValueLabel={true}
                formatOptions={{style: "unit", unit: "second", unitDisplay: "narrow"}}
                value={value}
                minValue={0}
                maxValue={10}
                classNames={{svg: "w-36 h-36", value: "text-3xl font-semibold text-white", indicator: "stroke-white"}}
                />
            </CardBody>
            <CardFooter className="w-full flex flex-col justify-center items-center">
                <Button onClick={() => navigate("/")}>
                    Go home now!
                </Button>
            </CardFooter>
        </Card>
      </LayoutNav>
    );
}

export default NoPage;