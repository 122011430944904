import { useEffect, useState } from "react";
import LayoutNav from "./layouts/LayoutNav";
import { Divider } from "@nextui-org/react";
import { Helmet } from "react-helmet";

import JobCard from "./components/JobCard";
import EduCard from "./components/EduCard";

function Experience() {
    let [experienceJsx, updateExperienceJsx] = useState(<></>);
    let [educationJsx, updateEducationJsx] = useState(<></>);
    
    useEffect(() => {        
        (async() => {
            let educationData = await fetchEduData();
            let experienceData = await fetchExpData();
            updateEducationJsx(buildEduSection(educationData));
            updateExperienceJsx(buildExpSection(experienceData));
        })();

    }, []);

    return (
      <LayoutNav>
        <Helmet>
            <title>Kait0u's Portfolio | Experience</title>
        </Helmet>
        <div className="mb-2" />
        <div className="w-full py-6 flex flex-col justify-center items-center">
            <h2 className="font-jbmono">My experience</h2>
            <p>Find out where I studied and worked!</p>
            <div className="mb-4" />

            <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-4 align-middle justify-start">
                <div className="col-span-2 flex flex-col items-center">
                    <h3 className="font-jbmono mb-3">Job experience</h3>
                    {experienceJsx}
                </div>

                <div className="hidden md:flex justify-center col-span-1">
                    <Divider orientation="vertical"/>
                </div>
                
                <div className="col-span-2 flex flex-col items-center">
                    <h3 className="font-jbmono mb-3">Education</h3>
                    {educationJsx}
                </div>
            </div>
        </div>
      </LayoutNav>
    );
}

async function fetchEduData() {
    const apiUrl = process.env.REACT_APP_MY_API;
    return await (await fetch(`${apiUrl}/education`)).json();
}

async function fetchExpData() {
    const apiUrl = process.env.REACT_APP_MY_API;
    return await (await fetch(`${apiUrl}/experience`)).json();
}

function buildEduSection(entries) {
    return (
        <>{entries.map((entry) => <EduCard school={entry} />)}</>
    );
}

function buildExpSection(entries) {
    return (
        <>{entries.map((entry) => <JobCard job={entry} />)}</>
    );
}

export default Experience;