import Layout from "./layouts/Layout";
import HomeNav from "./components/HomeNav";

function Home() {
    return (
      <Layout>
        <HomeNav />
      </Layout>
    );
}

export default Home;