import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LayoutNav from "./layouts/LayoutNav";

import ProjectCard from "./components/ProjectCard";
import FeatProjectCard from "./components/FeatProjectCard";



function Projects() {
  let [projData, updateProjData] = useState([])
  let [featData, updateFeatData] = useState({})

  useEffect(() => {        
    (async() => {
      const data = await fetchProjData();
      updateProjData(data);
      for (let proj of data) {
        if (proj.featured) { updateFeatData(proj); break; }
      }
    })();

  }, []);

  return (
    <LayoutNav>
      <Helmet>
        <title>Kait0u's Portfolio | Projects</title>
      </Helmet>
      <div className="mb-2" />
      <div className="w-full py-6 flex flex-col justify-center items-center">
        <h2 className="font-jbmono">My projects</h2>
        <p>Cool things I've worked on!</p>
        <div className="mb-6" />
          <h3 className="font-jbmono">Featured</h3>
          <FeatProjectCard proj={featData} />
        <div className="mb-6" />

        <h3 className="font-jbmono">All projects</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full place-items-stretch">
          {projData.map((proj) => (<ProjectCard proj={proj}/>))}
        </div>

      </div>
    </LayoutNav>
  );
}

export default Projects;

async function fetchProjData() {
  const apiUrl = process.env.REACT_APP_MY_API;
  return await (await fetch(`${apiUrl}/projects`)).json();
}