import { Divider } from "@nextui-org/react";
import LayoutNav from "./layouts/LayoutNav";
import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import Achievement from "./components/Achievement";




function Achievements() {

  let [achievData, updateAchievData] = useState([])

  useEffect(() => {        
    (async() => {
      const data = await fetchAchievementsData();
      updateAchievData(data);
    })();

  }, []);

  
    return (
      <LayoutNav>
        <Helmet>
          <title>Kait0u's Portfolio | Achievements</title>
        </Helmet>
        <div className="mb-2" />
        <div className="w-full py-6 flex flex-col justify-center items-center">
          <h2 className="font-jbmono">My achievements</h2>
          <p>What I'm proud of!</p>
          <div className="mb-4" />

          {achievData.map((ach, idx) => 
            <>
              <Achievement achievement={ach} />
              {idx < achievData.length - 1 ? <Divider className="w-full"/> : <></> }
            </>
          )}

        </div>
      </LayoutNav>
    );
}

async function fetchAchievementsData() {
  const apiUrl = process.env.REACT_APP_MY_API;
  return await (await fetch(`${apiUrl}/achievements`)).json();
}

export default Achievements;