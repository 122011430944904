import React from "react";
import { Card, CardHeader, CardBody } from "@nextui-org/react";

function SkillCard({skill}) {
    return (
        <Card className="mb-2">
            <CardHeader className="mb-0 pt-2 pb-0">
                <h3 className="mb-0 mt-1 md:mt-0">{skill.name}</h3>
            </CardHeader>
            <CardBody>
                <p>{skill.description}</p>
                {skill.points ? <ul className="list-disc list-inside">{skill.points.map((point) => <li className="ml-3">{point}</li>)}</ul> : <></>}
            </CardBody>
        </Card>
    );
}

export default SkillCard;