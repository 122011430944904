import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "./output.css";
import {NextUIProvider} from "@nextui-org/react";
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <NextUIProvider className="w-full flex flex-col justify-center items-center">
      <main className="h-full w-full flex flex-col content-center justify-center dark text-foreground bg-background">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </main>
    </NextUIProvider>
  </React.StrictMode>
);

