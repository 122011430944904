import React from "react";
import { Card, CardHeader, CardBody, CardFooter, Button, Image, Chip } from "@nextui-org/react";
import { Link } from "react-router-dom";

function ProjectCard({proj}) {
    const apiUrl = process.env.REACT_APP_MY_API;

    return (
        <Card className="w-full h-full">
            <CardHeader className="pb-0 flex justify-between items-center">
            <h5 className="font-jbmono mb-0 w-full">{proj.name}</h5>
            <div className="flex gap-2">
                {proj.featured ? <Chip color="warning">Featured!</Chip> : <></>}
                <Chip>{proj.mainTech}</Chip>
            </div>
            </CardHeader>
            <div className="my-4 flex justify-center items-center w-full">
            <Image alt={proj.name} shadow="none" src={proj.imageLink ? proj.imageLink : `${apiUrl}/images/${proj.imagePath}`} className="w-full" />
            </div>
            <CardBody>
            <p>{proj.description}</p>
            </CardBody>
            <CardFooter className="w-full flex justify-center">
            <Button as={Link} to={proj.link} isDisabled={!proj.link} color="primary">Check it out!</Button>
            </CardFooter>
        </Card>
    );
}

export default ProjectCard;