import { Routes, Route } from 'react-router-dom';

import Home from "./pages/Home";
import About from "./pages/About";
import Skills from './pages/Skills';
import Achievements from './pages/Achievements';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import NoPage from "./pages/NoPage";

function App() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<NoPage />} />
       </Routes>
    );
}

export default App;
