import React from "react";
import { Card, CardHeader, CardBody, Chip, Divider } from "@nextui-org/react";

function EduCard({school}) {

    return (
        <Card className="mb-3 w-full">
            <CardHeader className="flex justify-between items-center">
                <div className="flex flex-col justify-between gap-0.5 h-full">
                    <h5 className="mb-0">{school.name}</h5>
                    <p className="text-small text-default-500">{school.location}</p>
                </div>
                <div className="w-1/4 flex flex-col justify-between gap-0.5 items-end">
                    {school.inProgress ? <Chip color="primary" className="mb-1 min-w-full text-center">In Progress</Chip> : <Chip color="success" className="mb-1 min-w-full text-center">Complete</Chip>}
                    <p className="text-small text-default-500 text-center w-full">{school.years[0]} - {school.years[1]}</p>
                    
                </div>
            </CardHeader>
            <Divider className="w-full"/>
            <CardBody>
                <p className="w-full mb-2 text-center text-lg font-semibold">{school.type}</p>
                <ul className="list-disc ml-4">
                    {school.points.map((item) => <li>{item}</li>)}
                </ul>
            </CardBody>
        </Card>
    );
}

export default EduCard;