import React from "react";

import {
    Navbar,
    NavbarContent,
    NavbarItem,
    Link,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
} from "@nextui-org/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faWandMagicSparkles, faStar, faHourglassHalf, faDiagramProject, faSquareCaretDown } from "@fortawesome/free-solid-svg-icons";

function Nav() {

    const icons = {
        about: <FontAwesomeIcon icon={faUserAlt} className="pr-2" size="lg" fixedWidth />,
        skills: <FontAwesomeIcon icon={faWandMagicSparkles} className="pr-2" size="lg" fixedWidth />,
        achievements: <FontAwesomeIcon icon={faStar} className="pr-2" size="lg" fixedWidth />,
        experience: <FontAwesomeIcon icon={faHourglassHalf} className="pr-2" size="lg" fixedWidth />,
        projects: <FontAwesomeIcon icon={faDiagramProject} className="pr-2" size="lg" fixedWidth />
    };

    return (
        <div className="w-full">
        <Navbar>
            <NavbarContent className="w-full gap-4" justify="center">
                <NavbarItem>
                    <Link color="foreground" href="/">
                        Home
                    </Link>
                </NavbarItem>
                <Dropdown>
                    <NavbarItem>
                        <DropdownTrigger>
                            <Link color="foreground" href="#">
                                Info <FontAwesomeIcon icon={faSquareCaretDown} className="pl-2"/>
                            </Link>
                        </DropdownTrigger>
                    </NavbarItem>
                    <DropdownMenu>
                        <DropdownItem description="Get to know me!" startContent={icons.about} href="/about">
                            About
                        </DropdownItem>
                        <DropdownItem description="What helps me get the job done!" startContent={icons.skills} href="/skills">
                            Skills
                        </DropdownItem>
                        <DropdownItem description="Fruits of labor!" startContent={icons.achievements} href="/achievements">
                            Achievements
                        </DropdownItem>
                        <DropdownItem description="Where I studied and worked!" startContent={icons.experience} href="/experience">
                            Experience
                        </DropdownItem>
                        <DropdownItem description="Check out what I made!" startContent={icons.projects} href="/projects">
                            Projects
                        </DropdownItem>
                    </DropdownMenu>
                    
                </Dropdown>
                <NavbarItem>
                    <Link color="foreground" href="/contact">
                        Contact
                    </Link>
                </NavbarItem>
                
                    
            </NavbarContent>
        </Navbar>
        </div>
    );
}

export default Nav;
