import React from "react";
import Typed from "typed.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from "@fortawesome/free-solid-svg-icons";

function Title({margin}) {
    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
          strings: [
            'Jakub Jaworski',
            'Kait0u'
        ],
        startDelay: 500,
        typeSpeed: 75,
        backDelay: 4000,
        backSpeed: 50,
        loop: true
        });
    
        return () => {
          typed.destroy();
        };
      }, []);    

    return (
        <div id="title" className={`flex flex-col justify-center items-center mb-${margin ? margin : 4} text-center font-jbmono`}>
            <h1>
                <span ref={el}/>
            </h1>
            <h3 className="font-thin">
              <FontAwesomeIcon icon={faGear} spin className="px-2 md:px-3" style={{"--fa-animation-duration": "4s"}} />
                PROGRAMMER, CODING TUTOR
              <FontAwesomeIcon icon={faGear} spin spinReverse className="px-2 md:px-3" style={{"--fa-animation-duration": "4s"}} />
            </h3>
        </div>
    );
}

export default Title;