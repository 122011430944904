import React from "react";
import { Card, CardHeader, CardBody, Chip, Divider } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from "@fortawesome/free-solid-svg-icons";

function JobCard({job}) {

    const yearsRangeJsx = (years, inProgress) => {
        if (years.length === 2) return <p className="text-small text-default-500 text-center w-full">{`${years[0]} - ${years[1]}`}</p>
        else if (years.length === 1 && inProgress) return <p className="text-small text-default-500 text-center w-full">{`${years[0]}`} <FontAwesomeIcon icon={faGear} spin className="px-1" style={{"--fa-animation-duration": "4s"}} /></p>
        else return <p className="text-small text-default-500 text-center w-full">{`${years[0]}`}</p>
    };

    return (
        <Card className="mb-3 w-full">
            <CardHeader className="flex justify-between items-center">
                <div className="flex flex-col justify-between gap-0.5 h-full">
                    <h5 className="mb-0">{job.name}</h5>
                    <p className="text-small text-default-500">{job.location}</p>
                </div>
                <div className="w-1/4 flex flex-col justify-between gap-0.5 items-end">
                    {job.inProgress ? <Chip color="primary" className="mb-1 min-w-full text-center">In Progress</Chip> : <Chip color="success" className="mb-1 min-w-full text-center">Complete</Chip>}
                    {yearsRangeJsx(job.years, job.inProgress)}
                    
                </div>
            </CardHeader>
            <Divider className="w-full"/>
            <CardBody>
                <h5 className="mb-1">Duties:</h5>
                <ul className="list-disc ml-4">
                    {job.duties.map((item) => <li>{item}</li>)}
                </ul>
            </CardBody>
        </Card>
    );
}

export default JobCard;