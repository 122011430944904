import React from "react";
import { Badge, Card, CardBody } from "@nextui-org/react";
import { faPhotoFilm, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Achievement({achievement}) {
    return (
        <div className="w-full px-4 py-4 flex gap-5 group">
            {createTrophyIcon(achievement)}
            <div className="flex flex-col justify-center">
                <h4>{achievement.name}</h4>
                {achievement.description.map((par) => <p>{par}</p>)}
            </div>
        </div>
    );
}

function createTrophyIcon(achievement) {
    let result = (
      <Card className="hidden md:block">
        <CardBody>
          <FontAwesomeIcon icon={faTrophy} className=" text-7xl group-hover:text-yellow-300 duration-500 "/>
        </CardBody>
      </Card>
    );
  
    if (achievement.pictures) {
      result = (
      <Badge size="lg" color="primary" content={<FontAwesomeIcon icon={faPhotoFilm}/>} className="hidden md:block">
        {result}
      </Badge>);
    }
  
    return result;
  }

export default Achievement;