import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from "react-router-dom";

import { faUserAlt, faWandMagicSparkles, faStar, faHourglassHalf, faDiagramProject, faAddressCard } from "@fortawesome/free-solid-svg-icons";



function HomeNav() {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-2 gap-4 p-4 md:grid-cols-3">
            
            <Card className="card w-full h-full" isPressable isHoverable onPress={(e) => navigate("/about")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">ABOUT</p>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faUserAlt} size="6x" className="faicon"/>
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">Get to know me!</p>
                    
                </CardFooter>
            </Card>
            
            
            

            <Card className="card" isPressable isHoverable onPress={(e) => navigate("/skills")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">SKILLS</p>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faWandMagicSparkles} size="6x" className="faicon" />
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">What helps me get the job done!</p>
                    
                </CardFooter>
            </Card>

            <Card className="card" isPressable isHoverable onPress={(e) => navigate("/achievements")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">ACHIEVEMENTS</p>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faStar} size="6x" className="faicon" />
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">Fruits of labor!</p>
                    
                </CardFooter>
            </Card>

            <Card className="card" isPressable isHoverable onPress={(e) => navigate("/experience")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">EXPERIENCE</p>
                </CardHeader>
                <CardBody>
                <FontAwesomeIcon icon={faHourglassHalf} size="6x" className="faicon" />
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">Where I studied and worked!</p>
                    
                </CardFooter>
            </Card>

            <Card className="card" isPressable isHoverable onPress={(e) => navigate("/projects")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">PROJECTS</p>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faDiagramProject} size="6x" className="faicon" />
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">Check out what I made!</p>
                    
                </CardFooter>
            </Card>

            <Card className="card" isPressable isHoverable onPress={(e) => navigate("/contact")}>
                <CardHeader className="flex gap-3 card-h">
                    <p className="w-full text-center">CONTACT</p>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faAddressCard} size="6x" className="faicon" />
                </CardBody>
                <CardFooter className="card-f">
                    <p className="w-full text-center">Where to find me!</p>
                    
                </CardFooter>
            </Card>
        </div>
    );
}

export default HomeNav;