import { useEffect, useState } from "react";
import LayoutNav from "./layouts/LayoutNav";
import { Card, CardBody, Image } from "@nextui-org/react";
import { Helmet } from "react-helmet";


function About() {
  let [dataComponent, updateDataComponent] = useState(<></>);
  const apiUrl = process.env.REACT_APP_MY_API;

  useEffect(() => {
    (async () => {
      let data = await fetchData();
      updateDataComponent(createInfoProfile(data));
    })();
  }, []);

  const imageItemClasses = {
    wrapper: "flex w-full h-full flex-col items-center justify-center",
    img: "w-full h-auto"
  };

  return (
    <LayoutNav>
      <Helmet>
        <title>Kait0u's Portfolio | About</title>
      </Helmet>
      <div className="mb-2" />
      <div className="py-6 flex flex-col justify-center items-center">
        <h2 className="font-jbmono">
          <span>Get to know me!</span>
        </h2>
        <Card
          isBlurred
          className="border-none bg-background/60 dark:bg-default-100/50 max-w-[1200px]"
          shadow="sm"
        >
          <CardBody>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="w-full h-full flex flex-col items-center justify-center max-w-full">
                <Image
                    alt="Me!"
                    // shadow="md"
                    src={`${apiUrl}/images/about/me.jpg`}
                    classNames={imageItemClasses}
                    className=""
                />
              </div>
              <div className="w-full h-full flex flex-col justify-center items-center">
                {dataComponent}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </LayoutNav>
  );
}

async function fetchData() {
  const apiUrl = process.env.REACT_APP_MY_API;
  return await (await fetch(`${apiUrl}/about`)).json();
}

function createInfoProfile(data) {
  return (
  <div className="w-full">
    <h2 className="mb-0">{data.name}</h2>
    <h3 className="font-light">{data.nickname}</h3>
    {createInfoRow("Age", data.age)}
    {createInfoRow("From", data.location)}
    <div className="mb-3"/>
    {data.description?.map(((par) => <p className="mb-4">{par}</p>))}
    {createInfoList("Hobbies", data.hobbies)}
  </div>);
}

function createInfoRow(label, value) {
  return (
    <div className="grid grid-cols-2 align-middle">
      <div>
        <h3 className="mb-1">{label}</h3>
      </div>
      <div className="w-full h-full flex items-center">
        {value}
      </div>
    </div>
  );
}

function createInfoList(label, list) {
  const colLength = Math.ceil(list.length / 2);
  let col1 = [];
  let col2 = [];

  for (const item of list) {
    (col1?.length < colLength ? col1 : col2).push(item);
  }

  return (
    <>
      <h3 className="mb-1">{label}</h3>
      <div className="w-full grid-cols-1 grid md:grid-cols-2">
        <div>
          <ul className="list-disc ml-4">
            {col1.map((item) => <li>{item}</li>)}
          </ul>
        </div>
        <div>
          <ul className="list-disc ml-4">
            {col2.map((item) => <li>{item}</li>)}
          </ul>
        </div>
      </div>
    </>
  );
}

export default About;
