import LayoutNav from "./layouts/LayoutNav";
import {Input, Textarea, Button, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter} from "@nextui-org/react";
import ReCAPTCHA from "react-google-recaptcha"

import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";

const MIN_MESSAGE_LENGTH = 75;

function Contact() {
  const navigate = useNavigate();
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const [modalHeaderText, updateModalHeaderText] = useState("Error!")
  const [modalBodyJsx, updateModalBodyJsx] = useState(<p>Nothing happened!</p>)
  const [isMessageSent, updateIsMessageSent] = useState(false);

  const apiUrl = process.env.REACT_APP_MY_API;
  const recaptchaPubKey = process.env.REACT_APP_RECAPTCHA_PUBKEY;
  const recaptchaRef = useRef();
  
  

  const submit = async() => {
    const form = document.getElementById("contact_form");
    const [name, email, subject, message] = [form.name.value.trim(), form.email.value.trim(), form.subject.value.trim(), form.message.value.trim()]
    const token = await recaptchaRef.current.executeAsync();
    
    let isNameValid = validateName(name);
    let isEmailValid = validateEmail(email);
    let isSubjectValid = validateSubject(subject);
    let isMessageValid = validateMessage(message);

    if (isNameValid && isEmailValid && isSubjectValid && isMessageValid) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("message", message);
      formData.append("recaptchaToken", token);

      const response = await fetch(`${apiUrl}/form`, {
        method: "POST",
        body: formData,
      });
    
      if (response.ok) {
        updateModalHeaderText("Message sent!");
        updateModalBodyJsx(
        <>
          <p>Thank you for your message!</p>
          <p>I will get back to you as soon as I can, and in the meantime, enjoy the site!</p>
        </>);
        updateIsMessageSent(true);

      } else {
        updateModalHeaderText("ERROR!");
        updateModalBodyJsx(
        <>
          <p>Something went wrong sending your message...</p>
          <p>Please check your input and/or try again later.</p>
          <p>Sorry about that! :(</p>
        </>);
      }
    } else {
      updateModalHeaderText("ERROR!");
      updateModalBodyJsx(
      <>
      {!isNameValid ? <p>Invalid name!</p> : <></>}
      {!isEmailValid ? <p>The email provided is not a valid email!</p> : <></>}
      {!isSubjectValid ? <p>Your subject empty!</p> : <></>}
      {!isMessageValid ? <p>Your message is too short! {`${message.length} < ${MIN_MESSAGE_LENGTH}`}</p> : <></>}
      </>); 
    }
    onOpen();
  };

  

    return (
      <LayoutNav>
        <Helmet>
          <title>Kait0u's Portfolio | Contact</title>
        </Helmet>
        <div className="mb-2" />
        <div className="py-6 flex flex-col justify-center items-center">
            <h2 className="font-jbmono">
                <span>Contact me!</span>
            </h2>
            <p>Use the form below to contact me. I don't bite! :)</p>
            
            <div className="mb-3" />

            <script src={`https://www.google.com/recaptcha/api.js?render=${recaptchaPubKey}`} />
            <form id="contact_form" className="flex flex-col gap-2 w-full lg:w-[60rem]">
                <Input isRequired type="text" name="name" label="Name" placeholder="Enter your name" className="mb-2"/>
                <Input isRequired type="email" name="email" label="Email" placeholder="Enter your email" className="mb-2" />
                <Input isRequired type="text" name="subject" label="Subject" placeholder="Enter the subject" className="mb-2"/>
                <Textarea isRequired name="message" label="Message" placeholder="Enter your message" minRows={8} className="mb-2" />
                <Button size="lg" color="primary" onPress={submit} className="w-full">Send message</Button>
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaPubKey}/>
            </form>

        </div>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} backdrop="blur" hideCloseButton>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">{modalHeaderText}</ModalHeader>
                <ModalBody>
                  {modalBodyJsx}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose} isDisabled={isMessageSent}>
                    Retry
                  </Button>
                  <Button color="primary" onPress={(e) => navigate("/")}>
                    Go Home
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </LayoutNav>
    );
}

function validateName(name) {
  return (name.length > 0);
}

function validateEmail(email) {
  const re = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/;
  return re.test(email);
}

function validateSubject(subject) {
  return (subject.length > 0);
}

function validateMessage(message) {
  return message.length >= MIN_MESSAGE_LENGTH;
}

export default Contact;