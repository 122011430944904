import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LayoutNav from "./layouts/LayoutNav";
import { Accordion, AccordionItem } from "@nextui-org/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faHandshakeSimple, faMicrochip, faTerminal } from "@fortawesome/free-solid-svg-icons";
import SkillCard from "./components/SkillCard";


function Skills() {
  // const areas = ["coding", "tech", "social", "languages"];
  let [codingSkills, updateCodingSkills] = useState([]);
  let [techSkills, updateTechSkills] = useState([]);
  let [socialSkills, updateSocialSkills] = useState([]);
  let [languageSkills, updateLanguageSkills] = useState([]);

  const itemClasses = {
    base: "mb-0 py-0",
    title: "mb-0 py-0",
    titleWrapper: "mb-0 py-0",
    heading: "mb-0 py-0"
  };

  useEffect(() => {
    fetchData()
    .then((result) => {
      updateCodingSkills(result.coding);
      updateLanguageSkills(result.languages);
      updateTechSkills(result.tech);
      updateSocialSkills(result.social);
    })
  }, []);

  return (
    <LayoutNav>
      <Helmet>
        <title>Kait0u's Portfolio | Skills</title>
      </Helmet>
      <div className="mb-2" />
        <div className="py-6 flex flex-col justify-center items-center w-full">
          <h2 className="font-jbmono">My skills</h2>
          <p>Find out what I can bring to the table!</p>
          <div className="mb-4" />

          <Accordion isCompact variant="bordered" className="w-full" itemClasses={itemClasses}>
          <AccordionItem key="1" aria-label="Coding" title={<h3 className="m-0">Coding</h3>} startContent={<FontAwesomeIcon icon={faTerminal} size="xs"/>}>
            { codingSkills.map((skill) => <SkillCard skill={skill} />)}
          </AccordionItem>
          <AccordionItem key="2" aria-label="Tech" title={<h3 className="mb-0">Tech</h3>} startContent={<FontAwesomeIcon icon={faMicrochip} size="xs"/>}>
            { techSkills.map((skill) => <SkillCard skill={skill} />)}
          </AccordionItem>
          <AccordionItem key="3" aria-label="Social" title={<h3 className="mb-0">Social</h3>} startContent={<FontAwesomeIcon icon={faHandshakeSimple} size="xs"/>}>
            { socialSkills.map((skill) => <SkillCard skill={skill} />)}
          </AccordionItem>
          <AccordionItem key="4" aria-label="Languages" title={<h3 className="mb-0">Languages</h3>} startContent={<FontAwesomeIcon icon={faGlobe} size="xs"/>}>
            { languageSkills.map((skill) => <SkillCard skill={skill} />)}
          </AccordionItem>
        </Accordion>
        </div>
      
    </LayoutNav>
  );
}

async function fetchData() {
  const apiUrl = process.env.REACT_APP_MY_API;
  const areas = ["coding", "tech", "social", "languages"];
  let [codingSkills, techSkills, socialSkills, languageSkills] = areas.map((area) => fetch(`${apiUrl}/skills/${area}`));
  [codingSkills, techSkills, socialSkills, languageSkills] = await Promise.all([(await codingSkills).json(), (await techSkills).json(), (await socialSkills).json(), (await languageSkills).json()]);
  let result = {};
  for (let i = 0; i < areas.length; ++i) {
    result[areas[i]] = [codingSkills, techSkills, socialSkills, languageSkills][i]
  }
  
  return result;
}

export default Skills;