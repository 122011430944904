import Title from "./layout_components/Title";
import Footer from "./layout_components/Footer";

function Layout({children}) {
    return (
        <div className="h-full flex flex-col justify-between gap-6">
            <div className="flex flex-col items-center justify-center">
                <Title />
                <div className="mb-8" />
                {children}
            </div>
            <Footer />
      </div>
    );
}

export default Layout;