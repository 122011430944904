import React from "react";
import { Card, CardHeader, CardBody, CardFooter, Button, Image, Chip } from "@nextui-org/react";
import { Link } from "react-router-dom";

function FeatProjectCard({proj}) {
    const apiUrl = process.env.REACT_APP_MY_API;

    return (
        <Card className="w-full h-full flex justify-center items-center md:flex-row">
          <div className="w-full h-full flex justify-center md:justify-start align-middle">
            <Image
              alt="Featured project"
              shadow="md"
              src={proj.imageLink ? proj.imageLink : `${apiUrl}/images/${proj.imagePath}`}
              className="w-full h-auto"
            />
          </div>
          <div>
            <CardHeader className="mt-2 pb-0 w-full flex md:justify-start justify-center">
              <h4 className="font-jbmono mb-0">{proj.name}</h4>
              <Chip className=" mx-5">{proj.mainTech}</Chip>
            </CardHeader>
            <CardBody>
              <p>{proj.description}</p>
            </CardBody>
            <CardFooter className="w-full flex md:justify-start justify-center">
              <Button as={Link} to={proj.link} className="font-semibold text-lg" color="primary">Check it out!</Button>
            </CardFooter>
          </div>
        </Card> 
    );
}

export default FeatProjectCard;